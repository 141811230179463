<template>
  <v-container>
    <h1> <textloader-component textKey="FrejaRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="FrejaBrödtext"/> </paragraph-component>
    <v-img :src="require(isDark ? '@/assets/Information/Futf/Freja.jpg' : '@/assets/Information/Futf/Freja.jpg')"
    max-width="100%" class="center"></v-img>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>
